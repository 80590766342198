"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var Icon = function Icon(_ref) {
  var active = _ref.active;
  return _react.default.createElement("svg", {
    viewBox: "0 0 100 100",
    role: "presentation",
    className: "a-icon icon-comment".concat(active ? ' active' : '')
  }, _react.default.createElement("path", {
    d: "M11.839 5.95c-3.247 0-5.89 3.283-5.89 7.32v55.096c0 4.035 2.643 7.319 5.89 7.319H23.73v15.943c0 .971.447 1.88 1.11 2.257.492.277 1.055.193 1.51-.23l19.422-17.97h42.389c3.247 0 5.889-3.284 5.889-7.32V13.27c0-4.037-2.642-7.32-5.89-7.32H11.84zM25.439 99a6.202 6.202 0 0 1-3.041-.81c-2.233-1.274-3.616-3.787-3.616-6.562V80.634h-6.943C5.862 80.634 1 75.131 1 68.366V13.27C1 6.504 5.862 1 11.84 1h76.323C94.138 1 99 6.504 99 13.27v55.096c0 6.765-4.862 12.268-10.838 12.268h-40.45l-17.995 16.65C28.527 98.39 27.007 99 25.44 99z",
    fillRule: "evenodd"
  }));
};
var _default = exports.default = Icon;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _propTypes = _interopRequireDefault(require("prop-types"));
var _commentItem = _interopRequireDefault(require("./comment-item"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var CommentList = function CommentList(props) {
  var commentItems = props.comments.map(function (comment) {
    return _react.default.createElement(_commentItem.default, {
      key: comment.id,
      titleType: props.titleType,
      comment: comment,
      displayName: props.displayName,
      contentKey: props.contentKey,
      siteKey: props.siteKey,
      endpoint: props.endpoint,
      refreshCommentList: props.refreshCommentList,
      url: props.url,
      id: comment.id
    });
  });
  return _react.default.createElement("ul", {
    className: "p-commentsTable"
  }, commentItems);
};
var _default = exports.default = CommentList;
CommentList.propTypes = {
  comments: _propTypes.default.array,
  titleType: _propTypes.default.string.isRequired,
  contentKey: _propTypes.default.string.isRequired,
  siteKey: _propTypes.default.string.isRequired,
  endpoint: _propTypes.default.string.isRequired,
  displayName: _propTypes.default.string,
  refreshCommentList: _propTypes.default.func,
  url: _propTypes.default.string
};
CommentList.defaultProps = {
  comments: []
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var Icon = function Icon(_ref) {
  var active = _ref.active;
  return _react.default.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 88.6875 52.8125",
    height: "52.813",
    width: "88.688",
    id: "svg2"
  }, _react.default.createElement("g", {
    transform: "matrix(1.25 0 0 -1.25 0 52.813)",
    id: "g10"
  }, _react.default.createElement("g", {
    transform: "scale(.1)",
    id: "g12",
    fillRule: "evenodd"
  }, _react.default.createElement("path", {
    id: "path14",
    d: "M354.77 21.719C206.758 21.719 106.406 0 89.117 0c-16.535 0-31.113 11.113-35.86 25.254C48.517 39.387 7.696 180.137 1.48 203.004c-6.082 22.344 6.594 44.551 37.567 44.551h176.742v21.687c-23.848 3.492-35.715 16.254-35.715 30.196 0 7.808 3.383 10.73 5.668 31.941 1.067 9.816-4.183 8.527-7.32 8.851-9.102.934-13.91 4.461-19.285 13.875-2.821 4.918-6.524 17.481-6.524 20.305 0 17.836 14.953 24.731 40.383 24.731 18.883 0 35.207-5.332 41.578-16.996h41.653c0 35.562 35.625 40.316 78.543 40.316 42.91 0 78.527-4.754 78.527-40.316h41.66c6.367 11.664 22.695 16.996 41.57 16.996 25.43 0 40.383-6.895 40.383-24.731 0-2.824-3.703-15.387-6.515-20.305-5.391-9.414-10.196-12.941-19.297-13.875-3.125-.324-8.379.965-7.313-8.851 2.285-21.211 5.672-24.133 5.672-31.941 0-13.942-11.875-26.704-35.723-30.196v-21.687h176.739c30.976 0 43.644-22.207 37.57-44.551-6.211-22.867-47.023-163.617-51.777-177.75C651.52 11.114 636.938 0 620.414 0 603.121 0 502.77 21.719 354.77 21.719",
    fill: "#fff"
  }), _react.default.createElement("path", {
    id: "path16",
    d: "M654.32 316.773c-17.949 0-32.351 14.575-32.351 32.528 0 17.957 14.402 32.418 32.351 32.418 17.969 0 32.532-14.461 32.532-32.418 0-17.953-14.563-32.528-32.532-32.528zm0 59.579c-14.961 0-27.078-12.082-27.078-27.051 0-14.985 12.117-27.098 27.078-27.098 15.032 0 27.18 12.113 27.18 27.098 0 14.969-12.148 27.051-27.18 27.051zm.715-23.567c4.258 0 7.195 1.664 7.195 5.293 0 3.152-2.527 5.176-6.238 5.176h-9.054v-10.469h8.097zm-13.789 15.238h15.43c8.679 0 11.531-5.355 11.531-9.164 0-5.664-4.023-9.699-9.941-10.054v-.176c2.988-1.039 5.226-4.043 8.117-8.707l4.246-6.774h-6.883l-3.086 5.438c-4.394 7.797-6.105 9.348-10.402 9.348h-3.32v-14.786h-5.692v34.875",
    fill: "#15366f"
  }), _react.default.createElement("path", {
    id: "path18",
    d: "M215.785 286.609c-4.371-.16-14.144 5.036-16.84 7.727-1.8 1.797-2.539 4.512-1.425 8.098 4.144 13.379 6.562 32.132 5.3 46.894-.687 7.891-4.953 8.754-13.64 8.754-2.371 0-5.828-.519-8.707-.523-3.067-.008-4.535 1.035-5.457 2.765-1.641 3.031-4.504 10.91-5.407 14.551-.414 1.684.524 3.621 3.043 4.426 2.774.887 12.012 2.418 20.817 2.418 8.187 0 17.219-1.442 22.035-4.453 2.957-1.844 4.32-4.067 4.32-8.016v-77.086c0-2.18-1.703-5.461-4.039-5.555",
    fill: "#15366f"
  }), _react.default.createElement("path", {
    id: "path20",
    d: "M492.691 286.609c4.364-.16 14.141 5.036 16.836 7.727 1.805 1.797 2.547 4.512 1.426 8.098-4.14 13.379-6.555 32.132-5.293 46.894.672 7.891 4.949 8.754 13.633 8.754 2.371 0 5.828-.519 8.711-.523 3.055-.008 4.531 1.035 5.449 2.765 1.649 3.031 4.512 10.91 5.41 14.551.418 1.684-.519 3.621-3.039 4.426-2.773.887-12.011 2.418-20.82 2.418-8.172 0-17.215-1.442-22.031-4.453-2.957-1.844-4.336-4.067-4.336-8.016 0 0 .011-55.676.011-77.086 0-2.18 1.707-5.461 4.043-5.555",
    fill: "#15366f"
  }), _react.default.createElement("path", {
    id: "path22",
    d: "M471.371 262.559c0 3.164-3.086 5.718-6.883 5.718H244.105c-3.792 0-6.875-2.554-6.875-5.718v-11.118c0-3.156 3.083-5.722 6.875-5.722h220.383c3.797 0 6.883 2.566 6.883 5.722v11.118",
    fill: "#15366f"
  }), _react.default.createElement("path", {
    id: "path24",
    d: "M471.371 315.41c0 3.164-3.086 5.723-6.883 5.723H244.105c-3.792 0-6.875-2.559-6.875-5.723v-24.762c0-3.164 3.083-5.714 6.875-5.714h220.383c3.797 0 6.883 2.55 6.883 5.714v24.762",
    fill: "#15366f"
  }), _react.default.createElement("path", {
    id: "path26",
    d: "M315.82 372.961c0 3.445 2.903 6.195 6.094 6.664 9.488 1.387 18.305 2.098 32.387 2.098s22.89-.711 32.383-2.098c3.183-.469 6.093-3.219 6.093-6.664v-12.609H315.82v12.609zm148.618-12.609h-53.067v23.316c0 12.855-11.199 13.961-19.051 15.68-6.652 1.465-20.8 2.5-38.019 2.433-17.227-.07-31.379-.968-38.028-2.433-7.851-1.719-19.046-2.825-19.046-15.68v-23.316h-53.18c-3.793 0-6.879-2.567-6.879-5.723v-11.113c0-3.164 3.086-5.727 6.879-5.727h220.391c3.789 0 6.875 2.563 6.875 5.727v11.113c0 3.156-3.086 5.723-6.875 5.723",
    fill: "#15366f"
  }), _react.default.createElement("path", {
    id: "path28",
    d: "M670.336 225.758l-316.094-.004-316.094.004c-10.53.07-18.796-9.219-15.695-21.141 0 0 45.953-157.168 49.735-170.086 3.062-10.09 10.171-13.718 19-12.527 85.867 12.86 173.496 19.441 262.644 19.441H354.652c89.141 0 176.77-6.582 262.637-19.441 8.828-1.191 15.938 2.437 19.004 12.527 3.781 12.918 49.746 170.086 49.746 170.086 3.086 11.922-5.176 21.211-15.703 21.141",
    fill: "#ed192d"
  }), _react.default.createElement("path", {
    id: "path30",
    d: "M259.332 194.723c-13.098 0-17.633-1.614-17.633-1.614V68.711s5-1.563 17.633-1.563c12.625 0 18.117 1.563 18.117 1.563v124.398s-4.398 1.614-18.117 1.614",
    fill: "#fff"
  }), _react.default.createElement("path", {
    id: "path32",
    d: "M517.875 165.621c0 1.863 1.367 3.195 3.145 3.195l74.296-.007v24.254s-26.093 1.859-56.238 1.859c-31.223 0-56.965-1.777-56.965-1.777V68.769s28.34-1.867 56.965-1.867c28.848 0 56.238 1.809 56.238 1.809v24.355h-74.257c-1.817 0-3.164 1.368-3.164 3.157v20.523c0 1.91 1.308 3.141 3.164 3.141h54.355v24.39H521.02c-1.778 0-3.145 1.411-3.145 3.18.02.02 0 18.164 0 18.164",
    fill: "#fff"
  }), _react.default.createElement("path", {
    id: "path34",
    d: "M430.355 194.711c-12.859 0-17.859-1.602-17.859-1.602v-75.605c0-3.356-3.359-4.93-5.754-1.527l-53.574 77.132s-5.125 1.637-19.398 1.637c-14.266 0-22.661-1.637-22.661-1.637V68.711s4.571-1.563 17.778-1.563c12.953 0 17.972 1.563 17.972 1.563v74.984c0 3.586 3.84 4.332 5.5 1.778l54.032-76.762s5.937-1.602 20.859-1.602c13.496 0 21.035 1.602 21.035 1.602v124.398s-5.828 1.602-17.93 1.602",
    fill: "#fff"
  }), _react.default.createElement("path", {
    id: "path36",
    d: "M220.254 193.109s-27.816 1.813-61.746 1.813c-32.274 0-64.313-1.813-64.313-1.813v-25.507h43.102c1.773 0 3.164-1.403 3.164-3.223V68.684s5.801-1.497 18.047-1.497c12.25 0 17.726 1.497 17.726 1.497v95.695a3.193 3.193 0 0 0 3.215 3.223h40.805v25.507",
    fill: "#fff"
  }))));
};
var _default = exports.default = Icon;
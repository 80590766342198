"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.MapContainer = void 0;
var _react = _interopRequireDefault(require("react"));
var _propTypes = _interopRequireDefault(require("prop-types"));
var _googleMapsReact = require("google-maps-react");
var _this = void 0;
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _classCallCheck(a, n) { if (!(a instanceof n)) throw new TypeError("Cannot call a class as a function"); }
function _defineProperties(e, r) { for (var t = 0; t < r.length; t++) { var o = r[t]; o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, _toPropertyKey(o.key), o); } }
function _createClass(e, r, t) { return r && _defineProperties(e.prototype, r), t && _defineProperties(e, t), Object.defineProperty(e, "prototype", { writable: !1 }), e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _callSuper(t, o, e) { return o = _getPrototypeOf(o), _possibleConstructorReturn(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], _getPrototypeOf(t).constructor) : o.apply(t, e)); }
function _possibleConstructorReturn(t, e) { if (e && ("object" == _typeof(e) || "function" == typeof e)) return e; if (void 0 !== e) throw new TypeError("Derived constructors may only return object or undefined"); return _assertThisInitialized(t); }
function _assertThisInitialized(e) { if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); return e; }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _getPrototypeOf(t) { return _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (t) { return t.__proto__ || Object.getPrototypeOf(t); }, _getPrototypeOf(t); }
function _inherits(t, e) { if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function"); t.prototype = Object.create(e && e.prototype, { constructor: { value: t, writable: !0, configurable: !0 } }), Object.defineProperty(t, "prototype", { writable: !1 }), e && _setPrototypeOf(t, e); }
function _setPrototypeOf(t, e) { return _setPrototypeOf = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (t, e) { return t.__proto__ = e, t; }, _setPrototypeOf(t, e); }
var DEFAULT_ZOOM = 12;
var debounce = function debounce(callback, wait) {
  var context = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _this;
  var timeout = null;
  var callbackArgs = null;
  var later = function later() {
    return callback.apply(context, callbackArgs);
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    callbackArgs = args;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
var MapContainer = exports.MapContainer = function (_React$Component) {
  function MapContainer(props) {
    var _this2;
    _classCallCheck(this, MapContainer);
    _this2 = _callSuper(this, MapContainer, [props]);
    _this2.handleMarkerClick = _this2.handleMarkerClick.bind(_this2);
    _this2.handleMapClicked = _this2.handleMapClicked.bind(_this2);
    _this2.mapMoved = _this2.mapMoved.bind(_this2);
    _this2.datalayerPush = _this2.datalayerPush.bind(_this2);
    _this2.mapReady = _this2.mapReady.bind(_this2);
    _this2.state = {
      infoVisible: false,
      infoMarker: null,
      storeName: null,
      storeZip: null,
      place: null
    };
    _this2.changeListener = null;
    return _this2;
  }
  _inherits(MapContainer, _React$Component);
  return _createClass(MapContainer, [{
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.changeListener) {
        this.changeListener.remove();
      }
    }
  }, {
    key: "handleMarkerClick",
    value: function handleMarkerClick(props, marker) {
      var _this3 = this;
      var google = this.props.google;
      var service = new google.maps.places.PlacesService(marker.map);
      var request = {
        fields: ['place_id'],
        locationBias: marker.position,
        query: marker.name
      };
      service.findPlaceFromQuery(request, function (results, status) {
        var place = status === "OK" && results[0] ? results[0] : null;
        _this3.setState({
          infoVisible: true,
          infoMarker: marker,
          storeName: props.name,
          storeZip: props.zip,
          place: place
        });
      });
      this.datalayerPush({
        'event': 'gmapsMarkerClick',
        'store': "".concat(props.name, "|").concat(props.zip)
      });
    }
  }, {
    key: "mapMoved",
    value: function mapMoved() {
      this.datalayerPush({
        'event': 'gmapsMapMoved'
      });
    }
  }, {
    key: "datalayerPush",
    value: function datalayerPush(event) {
      if (window.dataLayer) {
        window.dataLayer.push(event);
      }
    }
  }, {
    key: "handleMapClicked",
    value: function handleMapClicked() {
      this.setState({
        infoVisible: false,
        infoMarker: null,
        storeName: null,
        storeZip: null,
        place: null
      });
    }
  }, {
    key: "renderInfoWindow",
    value: function renderInfoWindow() {
      var _this$state = this.state,
        marker = _this$state.infoMarker,
        place = _this$state.place;
      var url;
      if (place) {
        url = "https://www.google.com/maps/search/?api=1&query_place_id=".concat(place.place_id, "&query=").concat(encodeURIComponent(this.state.storeName));
      } else if (marker) {
        url = "https://www.google.com/maps/dir/?api=1&destination=".concat(marker.position.lat(), ",").concat(marker.position.lng());
      }
      return _react.default.createElement(_googleMapsReact.InfoWindow, {
        marker: marker,
        visible: marker != null
      }, _react.default.createElement("div", null, _react.default.createElement("h1", {
        className: "infowindow-heading"
      }, this.state.storeName), url ? _react.default.createElement("a", {
        href: url,
        target: "_blank",
        rel: "noopener noreferrer"
      }, "Vis p\xE5 Google Maps") : ''));
    }
  }, {
    key: "mapReady",
    value: function mapReady(mapProps, map) {
      var _this4 = this;
      var google = this.props.google;
      this.changeListener = google.maps.event.addListener(map, 'bounds_changed', debounce(function () {
        return _this4.props.onChange(mapProps, map);
      }, 500, this));
    }
  }, {
    key: "render",
    value: function render() {
      var _this5 = this;
      return _react.default.createElement(_googleMapsReact.Map, {
        ref: function ref(map) {
          return _this5._map = map;
        },
        google: this.props.google,
        initialCenter: this.props.initialPosition,
        zoom: DEFAULT_ZOOM,
        onReady: this.mapReady,
        onClick: this.handleMapClicked,
        onDragend: this.mapMoved,
        style: {
          width: '100%',
          height: '100%'
        }
      }, this.props.stores.map(function (s) {
        return _react.default.createElement(_googleMapsReact.Marker, {
          key: s.uniqueId,
          onClick: _this5.handleMarkerClick,
          position: s.location,
          name: s.name,
          zip: s.zip
        });
      }), this.renderInfoWindow());
    }
  }]);
}(_react.default.Component);
MapContainer.propTypes = {
  google: _propTypes.default.object.isRequired,
  onChange: _propTypes.default.func.isRequired,
  apiKey: _propTypes.default.string.isRequired,
  stores: _propTypes.default.array.isRequired,
  initialPosition: _propTypes.default.object
};
var _default = exports.default = (0, _googleMapsReact.GoogleApiWrapper)(function (props) {
  return {
    apiKey: props.apiKey
  };
})(MapContainer);
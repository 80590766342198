"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var Icon = function Icon(_ref) {
  var active = _ref.active;
  return _react.default.createElement("svg", {
    viewBox: "0 0 100 100",
    role: "presentation",
    className: "a-icon icon-close".concat(active ? ' active' : '')
  }, _react.default.createElement("path", {
    d: "M14.535 11L11 14.536l36.063 36.062-34.299 34.3 3.536 3.534 34.298-34.298 33.944 33.944 3.536-3.535-33.945-33.945 35.002-35L85.6 12.06 50.598 47.063z",
    fillRule: "evenodd"
  }));
};
var _default = exports.default = Icon;
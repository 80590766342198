"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addComment = addComment;
exports.addCommentVote = addCommentVote;
exports.getComments = getComments;
var _http = require("../../../utils/http");
function getComments(contentKey, siteKey, endpoint) {
  var URL = "".concat(endpoint, "/").concat(siteKey, "/").concat(contentKey, "/desc?format=json&newFirst=false");
  return (0, _http.httpGet)(URL, '', {
    cache: 'no-store'
  }).catch(function () {
    return [];
  });
}
function addComment(query, siteKey, endpoint) {
  var params = {
    key: query.contentKey,
    title: query.displayName,
    comment: query.comment,
    contenttitle: query.displayName,
    email: query.email,
    emailnotify: query.emailNotify ? 1 : 0,
    firstname: query.firstName,
    format: 'json'
  };
  var queryUrl = "".concat(endpoint, "/").concat(siteKey, "/").concat(query.contentKey);
  var referer = "http://www.tine.no".concat(query.url);
  if (query.parentCommentId) {
    params.parentcommentid = query.parentCommentId;
  }
  return (0, _http.httpPost)(queryUrl, jsonToEncodedString(params), {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    Referer: referer
  }).catch(function (error) {
    return error;
  });
}
function addCommentVote(commentId, siteKey, endpoint) {
  var ratingUrl = "".concat(endpoint, "/vote/").concat(siteKey, "/comment/").concat(commentId, "/1?format=json");
  return (0, _http.httpPost)(ratingUrl, {
    commentId: commentId
  }, '').catch(function (error) {
    return error;
  });
}
function jsonToEncodedString(json) {
  return Object.keys(json).map(function (key) {
    return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(json[key]));
  }).join('&');
}
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _propTypes = _interopRequireDefault(require("prop-types"));
var _close = _interopRequireDefault(require("../icons/close"));
var _i18n = require("../../../utils/i18n");
var _utils = require("./utils");
var _commentService = require("./comment-service");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _classCallCheck(a, n) { if (!(a instanceof n)) throw new TypeError("Cannot call a class as a function"); }
function _defineProperties(e, r) { for (var t = 0; t < r.length; t++) { var o = r[t]; o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, _toPropertyKey(o.key), o); } }
function _createClass(e, r, t) { return r && _defineProperties(e.prototype, r), t && _defineProperties(e, t), Object.defineProperty(e, "prototype", { writable: !1 }), e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _callSuper(t, o, e) { return o = _getPrototypeOf(o), _possibleConstructorReturn(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], _getPrototypeOf(t).constructor) : o.apply(t, e)); }
function _possibleConstructorReturn(t, e) { if (e && ("object" == _typeof(e) || "function" == typeof e)) return e; if (void 0 !== e) throw new TypeError("Derived constructors may only return object or undefined"); return _assertThisInitialized(t); }
function _assertThisInitialized(e) { if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); return e; }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _getPrototypeOf(t) { return _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (t) { return t.__proto__ || Object.getPrototypeOf(t); }, _getPrototypeOf(t); }
function _inherits(t, e) { if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function"); t.prototype = Object.create(e && e.prototype, { constructor: { value: t, writable: !0, configurable: !0 } }), Object.defineProperty(t, "prototype", { writable: !1 }), e && _setPrototypeOf(t, e); }
function _setPrototypeOf(t, e) { return _setPrototypeOf = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (t, e) { return t.__proto__ = e, t; }, _setPrototypeOf(t, e); }
var CommentForm = exports.default = function (_React$Component) {
  function CommentForm(props) {
    var _this;
    _classCallCheck(this, CommentForm);
    _this = _callSuper(this, CommentForm, [props]);
    _this.state = {
      firstname: '',
      email: '',
      comment: '',
      emailNotify: false,
      invalidEmail: false,
      allowSubmit: false
    };
    _this.handleChangeFirstname = _this.handleChange.bind(_this, 'firstname');
    _this.handleChangeComment = _this.handleChange.bind(_this, 'comment');
    _this.handleChangeEmail = _this.handleChange.bind(_this, 'email');
    _this.handleSubmit = _this.handleSubmit.bind(_this);
    _this._postComment = _this._postComment.bind(_this);
    _this._checkEmail = _this._checkEmail.bind(_this);
    _this._handleCheckbox = _this._handleCheckbox.bind(_this);
    return _this;
  }
  _inherits(CommentForm, _React$Component);
  return _createClass(CommentForm, [{
    key: "handleChange",
    value: function handleChange(name, event) {
      var change = {};
      change[name] = event.target.value;
      this.setState(change);
      if (name === 'email') {
        this._checkEmail(change);
      }
    }
  }, {
    key: "handleSubmit",
    value: function handleSubmit(event) {
      event.preventDefault();
      if (this.state.firstname !== '' && this.state.comment !== '' && this.state.email !== '') {
        this._postComment();
        this.setState({
          comment: '',
          firstname: '',
          email: ''
        });
      }
    }
  }, {
    key: "_handleCheckbox",
    value: function _handleCheckbox() {
      this.setState({
        emailNotify: !this.state.emailNotify
      });
    }
  }, {
    key: "_checkEmail",
    value: function _checkEmail(change) {
      if ((0, _utils.emailValidation)(change.email)) {
        this.setState({
          invalidEmail: false,
          allowSubmit: true
        });
      } else {
        this.setState({
          invalidEmail: true,
          allowSubmit: false
        });
      }
    }
  }, {
    key: "_postComment",
    value: function _postComment() {
      var _this2 = this;
      this.props.toggleCommentForm();
      (0, _commentService.addComment)({
        comment: this.state.comment,
        contentKey: this.props.contentKey,
        displayName: this.props.displayName,
        email: this.state.email,
        emailNotify: this.state.emailNotify,
        firstName: this.state.firstname,
        id: this.props.id,
        parentCommentId: this.props.parentCommentId,
        url: this.props.url
      }, this.props.siteKey, this.props.endpoint).then(function () {
        _this2.props.refreshCommentList();
      });
    }
  }, {
    key: "render",
    value: function render() {
      return _react.default.createElement("div", {
        className: "p-commentform"
      }, _react.default.createElement("form", {
        onSubmit: this.handleSubmit
      }, _react.default.createElement("textarea", {
        itemProp: "comment",
        placeholder: (0, _i18n.translate)('comments.comment').concat(' ...'),
        value: this.state.comment,
        onChange: this.handleChangeComment
      }), _react.default.createElement("input", {
        itemProp: "author",
        className: "firstname",
        placeholder: (0, _i18n.translate)('comments.firstname'),
        value: this.state.firstname,
        onChange: this.handleChangeFirstname
      }), _react.default.createElement("input", {
        itemProp: "email",
        className: this.state.invalidEmail ? 'invalid email' : 'email',
        placeholder: (0, _i18n.translate)('comments.email'),
        value: this.state.email,
        onChange: this.handleChangeEmail
      }), _react.default.createElement("input", {
        className: "notification",
        type: "checkbox",
        onChange: this._handleCheckbox
      }), " \xA0 ", (0, _i18n.translate)('comments.emailNotificationText'), _react.default.createElement("button", {
        className: "submit-comment-button",
        type: "submit",
        disabled: !this.state.allowSubmit
      }, " ", (0, _i18n.translate)('comments.addCommentButtonText'))), _react.default.createElement("button", {
        className: "btn-close",
        onClick: this.props.toggleCommentForm
      }, _react.default.createElement(_close.default, null)));
    }
  }]);
}(_react.default.Component);
CommentForm.propTypes = {
  parentCommentId: _propTypes.default.string,
  contentKey: _propTypes.default.string,
  siteKey: _propTypes.default.string.isRequired,
  endpoint: _propTypes.default.string.isRequired,
  displayName: _propTypes.default.string,
  id: _propTypes.default.string,
  toggleCommentForm: _propTypes.default.any,
  refreshCommentList: _propTypes.default.func,
  url: _propTypes.default.string
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateMapCoverage = void 0;
var toRad = function toRad(x) {
  return x * Math.PI / 180;
};
var getDistance = function getDistance(p1, p2) {
  var R = 6378137;
  var dLat = toRad(p2.lat - p1.lat);
  var dLong = toRad(p2.lng - p1.lng);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRad(p1.lat)) * Math.cos(toRad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};
var calculateMapCoverage = exports.calculateMapCoverage = function calculateMapCoverage(bounds) {
  var northWest = {
    lat: bounds.north,
    lng: bounds.west
  };
  var southEast = {
    lat: bounds.south,
    lng: bounds.east
  };
  return Math.floor(getDistance(northWest, southEast) / 1000) / 2;
};
"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _propTypes = _interopRequireDefault(require("prop-types"));
var _moment = _interopRequireDefault(require("moment"));
var _i18n = require("../../../utils/i18n");
var _thumb_up = _interopRequireDefault(require("../icons/thumb_up"));
var _thumb_up_fill = _interopRequireDefault(require("../icons/thumb_up_fill"));
var _tine_logo = _interopRequireDefault(require("../icons/tine_logo"));
var _commentForm = _interopRequireDefault(require("./comment-form"));
var _commentList = _interopRequireDefault(require("./comment-list"));
var _commentService = require("./comment-service");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _classCallCheck(a, n) { if (!(a instanceof n)) throw new TypeError("Cannot call a class as a function"); }
function _defineProperties(e, r) { for (var t = 0; t < r.length; t++) { var o = r[t]; o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(e, _toPropertyKey(o.key), o); } }
function _createClass(e, r, t) { return r && _defineProperties(e.prototype, r), t && _defineProperties(e, t), Object.defineProperty(e, "prototype", { writable: !1 }), e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _callSuper(t, o, e) { return o = _getPrototypeOf(o), _possibleConstructorReturn(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], _getPrototypeOf(t).constructor) : o.apply(t, e)); }
function _possibleConstructorReturn(t, e) { if (e && ("object" == _typeof(e) || "function" == typeof e)) return e; if (void 0 !== e) throw new TypeError("Derived constructors may only return object or undefined"); return _assertThisInitialized(t); }
function _assertThisInitialized(e) { if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); return e; }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
function _getPrototypeOf(t) { return _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (t) { return t.__proto__ || Object.getPrototypeOf(t); }, _getPrototypeOf(t); }
function _inherits(t, e) { if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function"); t.prototype = Object.create(e && e.prototype, { constructor: { value: t, writable: !0, configurable: !0 } }), Object.defineProperty(t, "prototype", { writable: !1 }), e && _setPrototypeOf(t, e); }
function _setPrototypeOf(t, e) { return _setPrototypeOf = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (t, e) { return t.__proto__ = e, t; }, _setPrototypeOf(t, e); }
var CommentItem = exports.default = function (_React$Component) {
  function CommentItem(props) {
    var _this;
    _classCallCheck(this, CommentItem);
    _this = _callSuper(this, CommentItem, [props]);
    _this.state = {
      upvotes: _this.props.comment.upvotes,
      showCommentForm: false,
      likeButtonClicked: false
    };
    _this.updateVotes = _this.updateVotes.bind(_this);
    _this.relativeTime = _this.relativeTime.bind(_this);
    _this.getLocalStorage = _this.getLocalStorage.bind(_this);
    _this.postCommentVote = _this.postCommentVote.bind(_this);
    _this._toggleCommentForm = _this._toggleCommentForm.bind(_this);
    return _this;
  }
  _inherits(CommentItem, _React$Component);
  return _createClass(CommentItem, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.getLocalStorage();
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(nextProps) {
      if (this.props.comment.id !== nextProps.comment.id) {
        this.getLocalStorage();
      }
    }
  }, {
    key: "getLocalStorage",
    value: function getLocalStorage() {
      if (JSON.parse(localStorage.getItem("VOTED_ON_COMMENT-".concat(this.props.comment.id))) !== null) {
        this.setState({
          likeButtonClicked: true
        });
      }
    }
  }, {
    key: "_toggleCommentForm",
    value: function _toggleCommentForm() {
      this.setState({
        showCommentForm: !this.state.showCommentForm
      });
    }
  }, {
    key: "updateVotes",
    value: function updateVotes() {
      this.postCommentVote();
      var voteData = {
        commentId: this.props.comment.id,
        voteTimeStamp: Math.round(new Date().getTime())
      };
      localStorage.setItem("VOTED_ON_COMMENT-".concat(this.props.comment.id), JSON.stringify(voteData));
      this.setState({
        likeButtonClicked: true,
        upvotes: this.state.upvotes + 1
      });
    }
  }, {
    key: "postCommentVote",
    value: function postCommentVote() {
      (0, _commentService.addCommentVote)(this.props.id, this.props.siteKey, this.props.endpoint);
    }
  }, {
    key: "relativeTime",
    value: function relativeTime(timeCreated) {
      _moment.default.locale('nb');
      var relativetime = (0, _moment.default)(timeCreated, 'x').fromNow();
      return relativetime;
    }
  }, {
    key: "parseLinks",
    value: function parseLinks(comment) {
      var urlRE = /https?:\/\/[^\s]*/gi;
      return comment.replace(urlRE, function (match) {
        return "<a href=\"".concat(match, "\" target=\"_BLANK\" rel=\"noopener noreferrer\">").concat(match, "</a>");
      });
    }
  }, {
    key: "render",
    value: function render() {
      return _react.default.createElement("div", {
        className: "p-comment-item",
        itemScope: true,
        itemType: "http://schema.org/Comment"
      }, _react.default.createElement("div", {
        className: "p-comment-comment",
        itemProp: "comment",
        dangerouslySetInnerHTML: {
          __html: this.props.comment.admin ? this.parseLinks(this.props.comment.comment) : this.props.comment.comment
        }
      }), _react.default.createElement("div", {
        className: "signature"
      }, this.props.comment.admin ? _react.default.createElement("span", null, _react.default.createElement(_tine_logo.default, null)) : null, _react.default.createElement("p", {
        className: "signature-firstname",
        itemProp: "author"
      }, " ", this.props.comment.firstname, " "), this.props.comment.lastname ? _react.default.createElement("p", null, " ", this.props.comment.lastname, " ") : null, _react.default.createElement("p", {
        className: "signature-dash"
      }, " \u2014 "), _react.default.createElement("p", {
        itemProp: "datePublished"
      }, " ", this.relativeTime(this.props.comment.created), " ")), _react.default.createElement("div", {
        className: "comment-options"
      }, _react.default.createElement("button", {
        onClick: this._toggleCommentForm
      }, (0, _i18n.translate)('comments.reply')), _react.default.createElement("button", {
        itemScope: true,
        itemType: "http://schema.org/LikeAction",
        className: "thumb-button",
        onClick: this.updateVotes,
        disabled: this.state.likeButtonClicked
      }, this.state.likeButtonClicked ? _react.default.createElement(_thumb_up_fill.default, null) : _react.default.createElement(_thumb_up.default, null)), "\xA0(", this.state.upvotes, ")", this.state.showCommentForm ? _react.default.createElement(_commentForm.default, {
        displayName: this.props.displayName,
        contentKey: this.props.contentKey,
        siteKey: this.props.siteKey,
        endpoint: this.props.endpoint,
        id: this.props.id,
        parentCommentId: this.props.comment.id,
        toggleCommentForm: this._toggleCommentForm,
        refreshCommentList: this.props.refreshCommentList,
        url: this.props.url
      }) : null), _react.default.createElement(_commentList.default, {
        titleType: (0, _i18n.translate)('comments.reply'),
        comments: this.props.comment.replies,
        displayName: this.props.displayName,
        contentKey: this.props.contentKey,
        siteKey: this.props.siteKey,
        endpoint: this.props.endpoint,
        id: this.props.id,
        refreshCommentList: this.props.refreshCommentList,
        url: this.props.url
      }), this.props.titleType === (0, _i18n.translate)('comments.reply') ? null : _react.default.createElement("hr", null));
    }
  }]);
}(_react.default.Component);
CommentItem.propTypes = {
  id: _propTypes.default.string.isRequired,
  titleType: _propTypes.default.string.isRequired,
  contentKey: _propTypes.default.string.isRequired,
  siteKey: _propTypes.default.string.isRequired,
  endpoint: _propTypes.default.string.isRequired,
  displayName: _propTypes.default.string,
  refreshCommentList: _propTypes.default.func,
  url: _propTypes.default.string,
  comment: _propTypes.default.shape({
    id: _propTypes.default.string.isRequired,
    comment: _propTypes.default.string.isRequired,
    firstname: _propTypes.default.string.isRequired,
    upvotes: _propTypes.default.number,
    downvotes: _propTypes.default.number,
    admin: _propTypes.default.bool,
    lastname: _propTypes.default.string,
    created: _propTypes.default.number,
    replies: _propTypes.default.array
  })
};
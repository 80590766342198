"use strict";

require("react-app-polyfill/ie11");
var _mount = _interopRequireWildcard(require("./utils/mount"));
var _i18n = _interopRequireDefault(require("./utils/i18n"));
var _translations = _interopRequireDefault(require("./utils/translations"));
var _index = require("./modules/comments/index");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_i18n.default.extend(_translations.default);
var tineuserfeedbackComments = function tineuserfeedbackComments() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('./modules/tineuserfeedbackComments/index'));
  });
};
var productMap = function productMap() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('./modules/productMap/index'));
  });
};
(0, _mount.default)('.tineuserfeedbackComments', tineuserfeedbackComments, ['contentKey', 'displayName', 'url', 'siteKey', 'endpoint']);
(0, _mount.default)('.productMap', productMap, ['sku', 'size', 'apikey', 'storeServiceUrl', 'heading']);
(0, _mount.MountLoaded)('.comments-react', _index.Component, ['contentKey', 'contentTitle', 'url', 'siteKey', 'endpoint']);
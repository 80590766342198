"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNumber = exports.getCommentAndReplies = exports.emailValidation = void 0;
var _htmlEntities = require("html-entities");
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
var isNumber = exports.isNumber = function isNumber(value) {
  return !Number.isNaN(value) && typeof value === 'number';
};
var htmlDecodeComment = function htmlDecodeComment(comment) {
  var entities = _htmlEntities.AllHtmlEntities;
  return _extends({}, comment, {
    comment: entities.decode(comment.comment),
    firstname: entities.decode(comment.firstname),
    email: entities.decode(comment.email),
    lastname: entities.decode(comment.lastname),
    authorTitle: entities.decode(comment.authorTitle),
    title: entities.decode(comment.title)
  });
};
var _getCommentAndReplies = exports.getCommentAndReplies = function getCommentAndReplies(comment) {
  if (!comment.replies) {
    return _extends({}, htmlDecodeComment(comment), {
      replies: []
    });
  }
  return _extends({}, htmlDecodeComment(comment), {
    replies: comment.replies.map(function (reply) {
      return _getCommentAndReplies(reply);
    })
  });
};
var emailValidation = exports.emailValidation = function emailValidation(email) {
  var emailPattern = new RegExp(['^(([^<>()[\\]\\.,;:\\s@]+(\\.[^<>()\\[\\]\\.,;:@]+)*)', '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.', '[0-9]{1,3})|(([a-zA-Z\\-0-9]+\\.)+', '[a-zA-Z]{2,}))$'].join(''));
  return emailPattern.test(email);
};
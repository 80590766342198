import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../utils/i18n';
import CommentList from './components/comment-list';
import CommentForm from './components/comment-form';
import CommentIcon from './icons/comment';
import { getComments } from './components/comment-service';
import { getCommentAndReplies } from './components/utils';

const PAGE_SIZE = 3;

export class Comments extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			comments: [],
			showCommentForm: false,
			page: 1
		};

		this._toggleCommentForm = this._toggleCommentForm.bind(this);
		this._updateCommentList = this._updateCommentList.bind(this);
		this._refreshCommentsList = this._refreshCommentsList.bind(this);
	}

	componentDidMount() {
		this._refreshCommentsList();
	}

	getCommentsList(comments) {
		if (comments && comments.length) {
			return (
				<CommentList
					displayName={this.props.displayName}
					contentKey={this.props.contentKey}
					siteKey={this.props.siteKey}
					endpoint={this.props.endpoint}
					comments={comments}
					titleType={translate('comments.comment')}
					refreshCommentList={this._refreshCommentsList}
					url={this.props.url}
				/>
			);
		}
		return null;
	}

	getCommentsForm() {
		if (this.state.showCommentForm) {
			return (
				<CommentForm
					contentKey={this.props.contentKey}
					displayName={this.props.displayName}
					siteKey={this.props.siteKey}
					endpoint={this.props.endpoint}
					toggleCommentForm={this._toggleCommentForm}
					refreshCommentList={this._refreshCommentsList}
					url={this.props.url}
				/>
			);
		}

		return (
			<button className="handle-commentForm" onClick={this._toggleCommentForm}>
				<CommentIcon />&nbsp; {translate('comments.addCommentButtonText')}
			</button>
		);
	}

	_refreshCommentsList() {
		getComments(this.props.contentKey, this.props.siteKey, this.props.endpoint)
			.then(data => this.setState({ comments: (data.map(comment => getCommentAndReplies((comment)))) }));
	}

	_getShowMoreCommentsButton() {
		if (this.state.comments.length <= this.state.page * PAGE_SIZE) {
			return null;
		}
		return (
			<button className="handle-commentForm" onClick={this._updateCommentList}> {translate('comments.showMore')}
			</button>
		);
	}

	_updateCommentList() {
		this.setState({ page: this.state.page + 1 });
	}

	_toggleCommentForm() {
		this.setState({ showCommentForm: !this.state.showCommentForm });
	}

	render() {
		const comments = this.state.comments.slice(0, this.state.page * PAGE_SIZE);

		return (
			<section
				className="o-comments"
				id="comments"
				itemScope
				itemType="http://schema.org/UserComments"
			>
				<h3>{translate('comments.heading')}</h3>
				{ this.getCommentsForm() }
				{ this.getCommentsList(comments) }
				{ this._getShowMoreCommentsButton() }
			</section>
		);
	}
}

Comments.propTypes = {
	endpoint: PropTypes.string.isRequired,
	siteKey: PropTypes.string.isRequired,
	contentKey: PropTypes.string.isRequired,
	displayName: PropTypes.string,
	url: PropTypes.string
};

export const Component = Comments;
export default { Component };

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  comments: {
    heading: 'Kommentarer',
    showMore: 'Vis flere kommentarer',
    commentsCount: 'Antall kommentarer',
    goToComments: 'Les kommentarer',
    addCommentButtonText: 'Legg til kommentar',
    comment: 'Kommentar',
    firstname: 'Fornavn',
    email: 'Epost',
    reply: 'Svar',
    sendComment: 'Send',
    emailNotificationText: 'Varsle meg på e-post ved svar fra andre brukere'
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.executeFetch = executeFetch;
exports.generateSearchParams = generateSearchParams;
exports.httpGet = httpGet;
exports.httpPost = httpPost;
exports.httpPostMultipart = httpPostMultipart;
require("whatwg-fetch");
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
var BASE_HEADERS = {
  'Accept-Encoding': 'gzip'
};
function generateSearchParams() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!params) {
    return '';
  }
  var searchParams = Object.keys(params).filter(function (key) {
    return key && params[key];
  }).filter(function (key) {
    return Array.isArray(params[key]) ? !!params[key].length : true;
  }).map(function (key) {
    if (Array.isArray(params[key])) {
      var valueString = params[key].map(function (value) {
        return encodeURIComponent(value);
      }).join(',');
      return "".concat(encodeURIComponent(key), "=").concat(valueString);
    }
    return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(params[key]));
  }).join('&');
  return "?".concat(searchParams);
}
function executeFetch(url, options) {
  return fetch(url, options).then(function (res) {
    return res.json();
  }).catch(function (error) {
    throw Error(error.message);
  });
}
function httpGet(path, params) {
  var url = path + generateSearchParams(params);
  var options = {
    method: 'GET',
    credentials: 'same-origin'
  };
  return executeFetch(url, options);
}
function httpPost(path, body) {
  var additionalHeaders = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var headers = _extends(additionalHeaders, BASE_HEADERS);
  var url = path;
  var options = {
    method: 'POST',
    headers: headers,
    body: body
  };
  return executeFetch(url, options);
}
function httpPostMultipart(path, formdata) {
  var url = path;
  var options = {
    method: 'POST',
    body: formdata
  };
  return executeFetch(url, options);
}
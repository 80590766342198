"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MountLoaded = MountLoaded;
exports.MountMap = MountMap;
exports.default = Mount;
var _react = _interopRequireDefault(require("react"));
var _reactDom = require("react-dom");
var _AsyncComponent = _interopRequireDefault(require("./AsyncComponent"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function Mount(selector, component) {
  var validProps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var targets = document.querySelectorAll(selector);
  for (var i = targets.length - 1; i >= 0; i--) {
    var elem = targets[i];
    var props = {};
    for (var _i = 0; _i < validProps.length; _i++) {
      var attributeValue = elem.getAttribute(validProps[_i]);
      if (attributeValue) {
        props[validProps[_i]] = attributeValue;
      }
    }
    (0, _reactDom.render)(_react.default.createElement(_AsyncComponent.default, _extends({
      moduleProvider: component
    }, props)), elem);
  }
}
function MountMap(selector, Component) {
  var validProps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var targets = document.querySelectorAll(selector);
  for (var i = targets.length - 1; i >= 0; i--) {
    var elem = targets[i];
    var props = {};
    for (var _i2 = 0; _i2 < validProps.length; _i2++) {
      var attributeValue = elem.getAttribute(validProps[_i2]);
      if (attributeValue) {
        props[validProps[_i2]] = attributeValue;
      }
    }
    (0, _reactDom.render)(_react.default.createElement(Component, props), elem);
  }
}
function MountLoaded(selector, Component, validProps) {
  Mount(selector, function () {
    return Promise.resolve({
      Component: Component
    });
  }, validProps);
}
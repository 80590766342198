"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var Icon = function Icon(_ref) {
  var active = _ref.active;
  return _react.default.createElement("svg", {
    viewBox: "0 0 100 100",
    role: "presentation",
    className: "a-icon icon-thumb_up".concat(active ? ' active' : '')
  }, _react.default.createElement("g", {
    fillRule: "evenodd"
  }, _react.default.createElement("path", {
    d: "M28.165 38.417c-.164 0-.328.023-.488.072L5.24 45.109a1.734 1.734 0 0 0-1.17 2.152l13.183 44.767a1.744 1.744 0 0 0 2.132 1.172l22.45-6.607a1.722 1.722 0 0 0 1.171-2.143L29.824 39.664a1.756 1.756 0 0 0-.837-1.036 1.713 1.713 0 0 0-.821-.211m-9.251 58.848a5.77 5.77 0 0 1-5.496-4.101L.234 48.39a5.739 5.739 0 0 1 3.877-7.118l22.427-6.62a5.664 5.664 0 0 1 4.369.467 5.785 5.785 0 0 1 2.754 3.415l13.182 44.778a5.747 5.747 0 0 1-.461 4.36 5.785 5.785 0 0 1-3.413 2.757l-22.442 6.604a5.61 5.61 0 0 1-1.613.23"
  }), _react.default.createElement("path", {
    d: "M52.044 73.672c1.536 0 3.33.073 5.408.26l5.84.504c3.98.334 7.368.619 9.635.91.966.124 1.89.186 2.742.186 3.254 0 7.417-.755 8.77-4.35.071-.201.147-.43.22-.666l.225-.738.662-.395c2.578-1.54 4.212-4.2 4.371-7.113l.017-.17a1.175 1.175 0 0 1 .012-.156l.094-.715.53-.492c1.711-1.586 2.718-3.715 2.836-5.994a8.405 8.405 0 0 0-.13-2.133l-.16-.861.522-.702a10.117 10.117 0 0 0 2.02-6.085c0-5.7-4.677-10.335-10.426-10.335l-1.527.014c-7.999 0-13.023-.831-15.065-1.608l-1.348-.513.062-1.441c.031-.731.073-1.488.126-2.242l.011-.116c.07-.53.146-1.052.222-1.566l.08-.546c.126-.886.245-1.722.308-2.483.536-6.6-.75-12.285-3.444-15.207-1.144-1.236-2.527-1.92-3.89-1.92-1.567 0-4.949.475-6.544 2.267a3.683 3.683 0 0 0-.929 2.903c.132 1.239.688 7.539-.874 13l-.066.233-.12.21c-3.01 5.263-11.534 14.502-16.4 18.374a1.737 1.737 0 0 0-.582 1.837l8.041 27.289c.26.87 1.183 1.408 2.06 1.197 1.134-.276 3.274-.637 6.691-.637m23.625 5.86c-1.02 0-2.115-.073-3.25-.217-2.182-.282-5.53-.563-9.407-.89l-5.91-.51c-6.36-.567-9.674.006-10.804.28-2.958.726-5.965-1.012-6.84-3.946l-8.042-27.293a5.698 5.698 0 0 1 1.926-6.1c4.437-3.53 12.43-12.16 15.297-17.02 1.305-4.806.75-10.564.681-11.214-.255-2.198.424-4.337 1.913-6.013 3.057-3.435 8.477-3.61 9.533-3.61 2.491 0 4.917 1.139 6.83 3.206 3.49 3.788 5.126 10.436 4.491 18.244-.07.884-.199 1.779-.335 2.726l-.082.567c-.07.47-.14.949-.204 1.434l-.033.498c2.102.46 6.097.967 12.254.967l1.526-.014c7.973 0 14.444 6.43 14.444 14.335 0 2.787-.799 5.455-2.317 7.77.09.756.11 1.51.058 2.25-.156 3.03-1.405 5.895-3.536 8.125-.39 3.748-2.443 7.142-5.613 9.294a6.267 6.267 0 0 1-.057.16c-1.693 4.507-6.137 6.97-12.523 6.97"
  })));
};
var _default = exports.default = Icon;
import React from 'react';
import PropTypes from 'prop-types';
import MapContainer from './components/map-container';
import { calculateMapCoverage } from './components/utils';

const defaultManualCenter = {
	lat: 59.8984328,
	lng: 10.5370038
};

const MODE_INIT = 1;
const MODE_ASK = 2;
const MODE_GEOLOC = 3;
const MODE_MANUAL = 4;
const MODE_FAILED = -1;

const Loading = () => (
	<div className="loading-spinner"/>
);

const missing = (str) => (!str || str.length === 0);


class ProductMap extends React.Component {
	constructor(props) {
		super(props);

		this.askPermission = this.askPermission.bind(this);
		this.handleLocation = this.handleLocation.bind(this);
		this.getStores = this.getStores.bind(this);
		this.errorHandler = this.errorHandler.bind(this);
		this.handleStoreResults = this.handleStoreResults.bind(this);
		this.mapMoved = this.mapMoved.bind(this);

		this.state = {
			location: null,
			stores: [],
			mode: MODE_INIT,
			loading: false
		};

		this.previousLocation = null;
		this.previousZoom = null;
	}

	componentDidMount() {
		if(missing(this.props.sku) || missing(this.props.apikey)) {
			this.setState({
				mode: MODE_FAILED
			});

			return;
		}

		if(typeof (navigator.permissions) === "undefined" || typeof(navigator.permissions.query) === "undefined") {
			this.setState({
				mode: MODE_ASK
			});

		} else {
			navigator.permissions.query({ name: 'geolocation' })
				.then(res => {
					if(res.state === "denied") {
						this.setState({
							mode: MODE_MANUAL,
							location: defaultManualCenter
						});

					} else if(res.state === "prompt") {
						this.setState({ mode: MODE_ASK });

					} else if(res.state === "granted") {
						this.askPermission();
					}
				});
		}
	}

	askPermission() {
		navigator.geolocation.getCurrentPosition(this.handleLocation, this.errorHandler);
	}

	getStores({ lat, lng}, dist) {
		this.setState({
			loading: true
		});

		const params = {
			lat, lng, dist,
			sku: this.props.sku
		};

		fetch(this.props.storeServiceUrl + '?' + new URLSearchParams(Object.entries(params)))
			.then(e => e.json())
			.then(this.handleStoreResults);
	}

	handleLocation(loc) {
		this.setState({
			mode: MODE_GEOLOC,
			location: {
				lat: loc.coords.latitude,
				lng: loc.coords.longitude
			}
		});
	}

	handleStoreResults(resp) {
		const newIds = resp.hits.map(s => s.uniqueId);
		const oldIds = this.state.stores.map(s => s.uniqueId);

		const existingStores = this.state.stores
			.filter(e => newIds.includes(e.uniqueId));

		const newStores = resp.hits
			.filter(e => !(oldIds.includes(e.uniqueId)));

		const finalObj = [
			...existingStores,
			...newStores.map(h => ({
				...h,
				location: {
					lat: h.location.lat,
					lng: h.location.lon
				}
			}))
		];

		this.setState({
			loading: false,
			stores: finalObj
		});
	}

	errorHandler() {
		this.setState({
			mode: MODE_MANUAL,
			location: defaultManualCenter
		});
	}

	// Decide if we want to fetch an updated list of stores, cause the map has moved
	mapMoved(mapProps, map) {
		if(typeof map.getBounds() === "undefined") {
			setTimeout(() => this.mapMoved(mapProps, map), 500);
			return;
		}

		const center = map.getCenter().toJSON();
		const bounds = map.getBounds().toJSON();
		const zoom = map.getZoom();

		const distanceNeeded = calculateMapCoverage(bounds);

		if(this.previousLocation != null && this.previousZoom != null) {
			const deltaLat = Math.abs(this.previousLocation.lat - center.lat);
			const deltaLng = Math.abs(this.previousLocation.lng - center.lng);
			const deltaZoom = Math.abs(this.previousZoom - zoom);

			if(deltaLat > 0.01 || deltaLng > 0.01 || deltaZoom > 0) {
				this.getStores(center, distanceNeeded);
			}

		} else {
			this.getStores(center, distanceNeeded);
		}

		this.previousLocation = center;
		this.previousZoom = zoom;
	}

	render() {
		if(this.state.mode === MODE_FAILED) {
			return null;
		}

		const heading = missing(this.props.heading) ? 'Utsalgssteder' : this.props.heading;

		if(this.state.mode === MODE_ASK) {
			return (
				<React.Fragment>
					<div className="title--part">
						<h2 className="t-heading t-text--center">{heading}</h2>
						<p className="t-preface article-preface">Godta forespørsel om lokasjon for å finne dette produktet i en butikk nær deg!</p>
					</div>
					<div className="product-map-container instructions">
						<button className="a-btn" onClick={this.askPermission}>Finn en butikk nær meg!</button>
					</div>
				</React.Fragment>
			);
		}

		if(this.state.mode === MODE_GEOLOC || this.state.mode === MODE_MANUAL) {
			return (
				<React.Fragment>
					<h2 className="t-heading t-text--center">{heading}</h2>
					<div className="product-map-container">
						{this.state.loading && <Loading/>}
						<MapContainer
							apiKey={this.props.apikey}
							initialPosition={this.state.location}
							onChange={this.mapMoved}
							stores={this.state.stores}
						/>
					</div>
				</React.Fragment>
			);
		}

		return <p>Laster...</p>;
	}
}

ProductMap.propTypes = {
	sku: PropTypes.string.isRequired,
	storeServiceUrl: PropTypes.string.isRequired,
	apikey: PropTypes.string.isRequired,
	heading: PropTypes.string
};

export const Component = ProductMap;
export default { Component };

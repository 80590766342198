"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var Icon = function Icon(_ref) {
  var active = _ref.active;
  return _react.default.createElement("svg", {
    viewBox: "0 0 100 100",
    role: "presentation",
    className: "a-icon icon-thumb_up".concat(active ? ' active' : '')
  }, _react.default.createElement("g", {
    fillRule: "evenodd"
  }, _react.default.createElement("path", {
    d: "M18.9,97.3c-2.5,0-4.8-1.7-5.5-4.1L0.2,48.4c-0.9-3,0.8-6.2,3.9-7.1l22.4-6.6c1.5-0.4,3-0.3,4.4,0.5c1.3,0.7,2.3,2,2.8,3.4 l13.2,44.8c0.4,1.5,0.3,3-0.5,4.4c-0.7,1.3-2,2.3-3.4,2.8L20.5,97C20,97.2,19.5,97.3,18.9,97.3"
  }), _react.default.createElement("path", {
    d: "M75.7,79.5c-1,0-2.1-0.1-3.3-0.2c-2.2-0.3-5.5-0.6-9.4-0.9l-5.9-0.5c-6.4-0.6-9.7,0-10.8,0.3c-3,0.7-6-1-6.8-3.9l-8-27.3 c-0.7-2.2,0.1-4.7,1.9-6.1c4.4-3.5,12.4-12.2,15.3-17c1.3-4.8,0.8-10.6,0.7-11.2c-0.3-2.2,0.4-4.3,1.9-6C54.3,3.2,59.7,3,60.8,3 c2.5,0,4.9,1.1,6.8,3.2c3.5,3.8,5.1,10.4,4.5,18.2c-0.1,0.9-0.2,1.8-0.3,2.7l-0.1,0.6c-0.1,0.5-0.1,0.9-0.2,1.4l0,0.5 c2.1,0.5,6.1,1,12.3,1l1.5,0c8,0,14.4,6.4,14.4,14.3c0,2.8-0.8,5.5-2.3,7.8c0.1,0.8,0.1,1.5,0.1,2.3c-0.2,3-1.4,5.9-3.5,8.1 c-0.4,3.7-2.4,7.1-5.6,9.3c0,0.1,0,0.1-0.1,0.2C86.5,77.1,82.1,79.5,75.7,79.5"
  })));
};
var _default = exports.default = Icon;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.translate = exports.default = void 0;
var _nodePolyglot = _interopRequireDefault(require("node-polyglot"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var POLYGLOT = new _nodePolyglot.default({});
var i18n = {
  extend: function extend(obj) {
    POLYGLOT.extend(obj);
  }
};
var _default = exports.default = i18n;
var translate = exports.translate = function translate(key, obj) {
  return POLYGLOT.t(key, obj);
};